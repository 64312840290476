import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 44474237230, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://testgmxapi.weequan.cyou",
  testTransactionUrl: "https://dev.deriw.com/trading-prop/transaction",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/transaction",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://testgmxapi.weequan.cyou/ws",
  explorer: "http://explorer.dev.deriw.com/",
  tx: 'http://explorer.dev.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet3',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  ethRpc: "https://rpc.dev.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复

  Vault: "0x99a03F97d895f29fd5B8316F73bad942b5EB67D0",
  Router: "0x9c65fCBEeD7E7262D8d880cB7Ebe3561CD061daB",
  VaultUtils: "0xE98B3317bF7B95f9840A702cefE6E8ab6D7fc82C",
  VaultReader: "0xa0be43beBd9ca006c7db880C37d9dBd7602C3Ce3",
  Reader: "0x835EFCcB69dBbf0Eed4193f5b226E4F87940a17a",
  GlpManager: "0x01926cF0F5445d89065612DE31f3B4d20143B00d",
  GlpRewardRouter: "0x06D6de304Cd8A846a4fF086923BD28459e8D33DB",
  NATIVE_TOKEN: "0x5D2e27A6378f9AaB7346A21386026c85eF9C9ba2", // (WETH)
  GLP: "0xf4FE271cAE71683a317D827Eb5dD7c3F42E16fcd",
  OrderBook: "0x2485245C02D2D3C03d2AC8E9F131C5E875D829c1",
  OrderBookReader: "0xbbd5df63085e7894C4782f84cE422fb55dCBDB9e",

  PositionRouter: "0xa2F84bD74A723cCFF8B04Fbe739C37572cF49755",
  ReferralStorage: "0x3BD4D10C9796c27C2E3769575aE78bEEC8979f1A",
  ReferralData: "0x0909D5B0bCCc1a165827886Ede3f5E59AF2c5E65",
  ReferralReader: "0x82C4d4aD24F69B265Bbe1a15aCf8aeeF53BA165c",
  Timelock: "0xE97616D8276A83228961aC795419faEA1107Dd2B",

  Slippage: "0x84ee78289A820C2f77A3a2C9F000E1164929287C",

  Phase: "0x5D1Ce03992d456B4C7014a5c40B4e7Bbe5feb78B",

  AuthV2: "0x05560a205d1127853f590A14CA502cEbf5AC185e",
  FoundFactoryV2: "0x6452E356598BB93547a69B423047A779982a0C63",
  PoolDataV2: "0x8153E6D0F43745EC10512FE41d41739A121fc225",
  LpCounter: "0x8F1fF1798ded0e152614d2bB858c51eCE400CF68",
  ErrorContractV2: "0x081f528481a4EeBAc14388926265722b2c64Ac8c",
  FoundRouterV2: "0x279106C7BFc174Baac6BCAb7564cA3E80522DC93",
  FoundReader: "0xC3c6820Aa3dEa745Fe90a1DB0E00d61f35d350CE",

  TestAuth: "0x67Bba14046f928c5BA3889F31014A73eB635B69c",
  TestPrpoFactory: "0x60b9bb7fbF382d31B52595721FD9FA31e372e96b",
  TestPropTradeData: "0x90E8891dc3f232b486e5aE52Ae04EF003969c0d0",
  TestErrContract: "0xB824Cec013A4A1Fc84B9E3AD79b273D6e7CbaF59",
  TestPropTradeRouter: "0x5B2D2b947759926e69C180c16fd42Fda0f2A8911",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003
  }
};
