import { AbstractConnector } from "@web3-react/abstract-connector";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Web3 from "web3";
import config from "../../config";
import { getWalletConnectConnector } from ".";
import { NETWORK_METADATA } from "../../config/chains";

export const URI_AVAILABLE = "URI_AVAILABLE";

export class UserRejectedRequestError extends Error {
  constructor() {
    super();
    this.name = this.constructor.name;
    this.message = "The user rejected the request!";
  }
}

export class WalletConnectConnector extends AbstractConnector {
  // private readonly config: EthereumProviderOptions;
  config

  // public walletConnectProvider?: EthereumProvider;
  walletConnectProvider
  // {projectId,showQrModal,chainId,supportedChainIds,rpcMap,}: {projectId: string;showQrModal: boolean;chainId: number;supportedChainIds: number[];rpcMap: { [networkId: number]: string };}
  constructor({
    keepLine = false,
    projectId,
    showQrModal,
    chainId,
    supportedChainIds,
    rpcMap,
  }) {
    super();
    this.keepLine = keepLine
    this.config = {
      // optionalChains这个不包含当前链id 会报
      // POST https://rpc.walletconnect.com/v1?chainId=eip155:44474237230&projectId=47bab16dc1a8b52e42e3c7c81bfe197e 400 (Bad Request)
      chains: [1],
      optionalChains:  [44474237230, 35318034165],
      // chains: [chainId],
      // optionalChains: supportedChainIds.filter((id) => id !== chainId),
      rpcMap,
      projectId,
      showQrModal,
      metadata: {
        name: 'DEX',
        description: 'Deriw - the first zero gas fee perpetual contract platform',
        url: window.location.origin, // origin must match your domain & subdomain
        icons: ['https://avatars.githubusercontent.com/u/37784886']
      },
      // methods: ['wallet_switchEthereumChain', 'wallet_addEthereumChain'],
      // events: ['chainChanged', 'accountsChanged', 'disconnect']
      // qrModalOptions: {
      //   enableExplorer: true,
      //   themeMode: "dark",
      //   themeVariables: {
      //     "--wcm-font-family": '"Relative",sans-serif',
      //     "--wcm-z-index": "1100",
      //   },
      // },
    };

    this.handleChainChanged = this.handleChainChanged.bind(this);
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    // EthereumProvider.init(this.config).then(res => {
    //   this.walletConnectProvider = res
    //   this.walletConnectProvider.on("chainChanged", this.handleChainChanged);
    //   this.walletConnectProvider.on("accountsChanged", this.handleAccountsChanged);
    //   this.walletConnectProvider.on("disconnect", this.handleDisconnect);
    //   this.walletConnectProvider.on("display_uri", this.handleDisplayURI);
    // })
  }

  // handleChainChanged(chainId: number | string): void
  handleChainChanged(chainId) {
    console.log('%c handleChainChanged', 'color: #00A0E9; font-size: 26px; font-weight: blod;', chainId);
    this.emitUpdate({ chainId });
  }

  // handleAccountsChanged(accounts: string[]): void
  handleAccountsChanged(accounts) {
    console.log('%c handleAccountsChanged', 'color: #00A0E9; font-size: 26px; font-weight: blod;', accounts);
    this.emitUpdate({ account: accounts[0] });
  }

  //  handleDisconnect(): void 
  handleDisconnect() {
    console.log('%c disconnect', 'color: #00A0E9; font-size: 26px; font-weight: blod;', );
    this.emitDeactivate();
  }

  // handleDisplayURI = (uri: string): void
  handleDisplayURI = (uri) => {
    console.log('%c handleDisplayURI', 'color: #00A0E9; font-size: 26px; font-weight: blod;', uri);
    this.emit(URI_AVAILABLE, uri);
  };

  // async activate(): Promise<ConnectorUpdate>
  async activate() {
    if (!this.walletConnectProvider) {
      // const AaaWalletConnectProvider = await import("@walletconnect/ethereum-provider").then((m) => m?.default ?? m);
      // this.config.chains = this.config.chains.map(item => String(item))
      this.walletConnectProvider = await EthereumProvider.init(this.config)
      this.walletConnectProvider.on("chainChanged", this.handleChainChanged);
      this.walletConnectProvider.on("accountsChanged", this.handleAccountsChanged);
      this.walletConnectProvider.on("disconnect", this.handleDisconnect);
      this.walletConnectProvider.on("display_uri", this.handleDisplayURI);
    }

    try {
      // If there is an active session, disconnect the current session.
      if (this.walletConnectProvider.session && !this.keepLine) { 
        await this.walletConnectProvider.disconnect();
      }
      
      const accounts = await this.walletConnectProvider.enable();
      const defaultAccount = accounts[0];
      const chainId = await this.getChainId();

      return { provider: this.walletConnectProvider, account: defaultAccount, chainId };
    } catch (error) {
      // @ts-ignore
      if (/request reset/i.test(error.message)) {
        throw new UserRejectedRequestError();
      }
      throw error;
    } finally {
      this.checkNetWork()
    }
  }

  // async getProvider(): Promise<typeof this.walletConnectProvider>
  async getProvider() {
    return this.walletConnectProvider;
  }

  // async getChainId(): Promise<number | string> 
  async getChainId() {
    return Promise.resolve(this.walletConnectProvider?.chainId);
  }

  async checkNetWork() {
    try {
      const chainId = await this.getChainId();
      const walletConnectProviderUrl = new Web3(this.walletConnectProvider);
      const walletConnectProviderUrlChainId = await walletConnectProviderUrl.eth.getChainId()
      if (walletConnectProviderUrlChainId != config.networkId) {
          const chainIdHex = "0x" + config.networkId.toString(16);
          const walletConnect = getWalletConnectConnector(true);
          if (walletConnect) {
            try {
              await walletConnect.walletConnectProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainIdHex }],
              })
              this.handleChainChanged(chainIdHex)
              } catch(error) {
              if (error && error.message && error.message.indexOf('32603') > -1) {
                try {
                  const netData = JSON.parse(JSON.stringify(NETWORK_METADATA[config.networkId]))
                  delete netData['nativeTokenSymbol']
                  delete netData['defaultCollateralSymbol']
                  delete netData['SWAP_ORDER_EXECUTION_GAS_FEE']
                  delete netData['INCREASE_ORDER_EXECUTION_GAS_FEE']
                  delete netData['DECREASE_ORDER_EXECUTION_GAS_FEE']
                  await walletConnect.walletConnectProvider.request({
                    method: "wallet_addEthereumChain",
                    params: [netData],
                  })
                  this.handleChainChanged(chainIdHex)
                }catch(addError) {
                }
              }
            }finally {
              console.log('%c finally', 'color: #00A0E9; font-size: 26px; font-weight: blod;', chainIdHex);
              console.log('%c finally', 'color: #00A0E9; font-size: 26px; font-weight: blod;', this.walletConnectProvider);
              // this.handleChainChanged(chainIdHex)
              // window.location.reload()
            }
          }
      } else {

        const chainIdHex = "0x" + config.networkId.toString(16);
        console.log('%c chainId等于testnet', 'color: #00A0E9; font-size: 26px; font-weight: blod;', chainIdHex);
        this.handleChainChanged(chainIdHex)
      }
    } catch (err) {}
  }

  // async getAccount(): Promise<null | string> 
  async getAccount() {
    //(accounts: string[]): string
    return Promise.resolve(this.walletConnectProvider?.accounts).then((accounts) => accounts[0]);
  }

  async deactivate() {
    if (!this.walletConnectProvider) return;
    try {
      if (!this.keepLine) {
        this.walletConnectProvider.disconnect();
      }
    } catch (error) {
      // as Error
      if (!/No matching key/i.test((error).message)) throw error;
    } finally {
      this.walletConnectProvider.removeListener("disconnect", this.handleDisconnect);
      this.walletConnectProvider.removeListener("chainChanged", this.handleChainChanged);
      this.walletConnectProvider.removeListener("accountsChanged", this.handleAccountsChanged);
      this.walletConnectProvider.removeListener("display_uri", this.handleDisplayURI);
    }
  }

  async close() {
    console.log('%c close', 'color: #00A0E9; font-size: 26px; font-weight: blod;', );
    this.emitDeactivate();
  }
}
