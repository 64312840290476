import { AbstractConnector } from "@web3-react/abstract-connector";
import { Eip6963Providers } from "./useWallet";
import { NETWORK_METADATA } from "../../config/chains";

export const URI_AVAILABLE = "URI_AVAILABLE";

export class UserRejectedRequestError extends Error {
  constructor() {
    super();
    this.name = this.constructor.name;
    this.message = "The user rejected the request!";
  }
}

export class Eip6963ConnectorConnector extends AbstractConnector {
  // private readonly config: EthereumProviderOptions;
  config

  // public eip6963ConnectProvider?: EthereumProvider;
  eip6963ConnectProvider

  // {projectId,showQrModal,chainId,supportedChainIds,rpcMap,}: {projectId: string;showQrModal: boolean;chainId: number;supportedChainIds: number[];rpcMap: { [networkId: number]: string };}
  constructor({
    projectId,
    showQrModal,
    chainId,
    supportedChainIds,
    rpcMap,
    type
  }) {
    super();

    this.config = {
      type: type,
      chains: [chainId],
      optionalChains: supportedChainIds.filter((id) => id !== chainId),
      rpcMap,
      projectId,
      showQrModal,
      qrModalOptions: {
        enableExplorer: true,
        themeMode: "dark",
        themeVariables: {
          "--wcm-font-family": '"Relative",sans-serif',
          "--wcm-z-index": "1100",
        },
      },
    };

    this.handleChainChanged = this.handleChainChanged.bind(this);
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
  }

  // handleChainChanged(chainId: number | string): void
  handleChainChanged(chainId) {
    this.emitUpdate({ chainId });
  }

  // handleAccountsChanged(accounts: string[]): void
  handleAccountsChanged(accounts) {
    this.emitUpdate({ account: accounts[0] });
  }

  //  handleDisconnect(): void 
  handleDisconnect() {
    this.emitDeactivate();
  }

  // handleDisplayURI = (uri: string): void
  handleDisplayURI = (uri) => {
    this.emit(URI_AVAILABLE, uri);
  };

  // async activate(): Promise<ConnectorUpdate>
  async activate() {
    if (!this.eip6963ConnectProvider) {
      const found = Eip6963Providers.find(({ id }) => {
        return id == this.config.type
      })
      this.eip6963ConnectProvider = found.provider
    }

    this.eip6963ConnectProvider.on("chainChanged", this.handleChainChanged);
    this.eip6963ConnectProvider.on("accountsChanged", this.handleAccountsChanged);
    this.eip6963ConnectProvider.on("disconnect", this.handleDisconnect);
    this.eip6963ConnectProvider.on("display_uri", this.handleDisplayURI);
    try {
      // If there is an active session, disconnect the current session.
      if (this.eip6963ConnectProvider.session) await this.eip6963ConnectProvider.disconnect();

      const accounts = await this.eip6963ConnectProvider.enable();
      const defaultAccount = accounts[0];
      // const desiredChainId = this.config.chains[0]
      const desiredChainId = window.location.href.indexOf('crossChain') > -1 ? 11155111 : this.config.chains[0]
      // 跨链默认切换l1
      const desiredChainIdHex = `0x${desiredChainId.toString(16)}`
      // const desiredChainIdHex = `0x${.toString(16)}`
    if (document.visibilityState == 'hidden') {
      return {}
    }
    console.log('%c 111', 'color: #00A0E9; font-size: 26px; font-weight: blod;', desiredChainId ,  this.config);
      return this.eip6963ConnectProvider
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: desiredChainIdHex }],
        })
        .catch((error) => {
          if (error.code == 4001) {
            return error
          }
          console.log("error", error)
          // https://github.com/MetaMask/metamask-mobile/issues/3312#issuecomment-1065923294
          // const errorCode = (error.data)?.originalError?.code || error.code

          // 4902 indicates that the chain has not been added to MetaMask and wallet_addEthereumChain needs to be called
          // https://docs.metamask.io/guide/rpc-api.html#wallet-switchethereumchain


          const netData = JSON.parse(JSON.stringify(NETWORK_METADATA[this.config.chains[0]]))
          delete netData['nativeTokenSymbol']
          delete netData['defaultCollateralSymbol']
          delete netData['SWAP_ORDER_EXECUTION_GAS_FEE']
          delete netData['INCREASE_ORDER_EXECUTION_GAS_FEE']
          delete netData['DECREASE_ORDER_EXECUTION_GAS_FEE']

          return this.eip6963ConnectProvider.request({
            method: 'wallet_addEthereumChain',
            params: [netData],
          })

        }).then((e) => {
          if (e && e.code == 4001) {
            return e
          }
          return { provider: this.eip6963ConnectProvider, account: defaultAccount, chainId: this.config.chains[0] };
        })

    } catch (error) {
      // @ts-ignore
      if (/request reset/i.test(error.message)) {
        throw new UserRejectedRequestError();
      }
      throw error;
    }
  }

  // async getProvider(): Promise<typeof this.eip6963ConnectProvider>
  async getProvider() {
    return this.eip6963ConnectProvider;
  }

  // async getChainId(): Promise<number | string> 
  async getChainId() {
    return Promise.resolve(this.eip6963ConnectProvider?.chainId);
  }

  // async getAccount(): Promise<null | string> 
  async getAccount() {
    //(accounts: string[]): string
    return Promise.resolve(this.eip6963ConnectProvider?.accounts).then((accounts) => accounts[0]);
  }

  async deactivate() {
    if (!this.eip6963ConnectProvider) return;
    try {

    } catch (error) {
      // as Error
      if (!/No matching key/i.test((error).message)) throw error;
    } finally {
      this.eip6963ConnectProvider.removeListener("disconnect", this.handleDisconnect);
      this.eip6963ConnectProvider.removeListener("chainChanged", this.handleChainChanged);
      this.eip6963ConnectProvider.removeListener("accountsChanged", this.handleAccountsChanged);
      this.eip6963ConnectProvider.removeListener("display_uri", this.handleDisplayURI);
    }
  }

  async isAuthorized() {
    if (this.config.type != "com.coinbase.wallet") {
      return true
    }
    return false;
  }

  async close() {
    this.emitDeactivate();
  }
}
