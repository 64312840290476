import { MAINNET, TESTNET, DERIWTESTNET, DERIWDEVNET } from "./chains";



const BACKEND_URLS = {
  default: "https://devdexapi.weequan.cyou/",

  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  // [TESTNET]: "http://localhost:3000/",
  [TESTNET]: "https://devdexapi.weequan.cyou/",
  [DERIWDEVNET]: "https://testgmxapi.weequan.cyou/arb/",
  [DERIWTESTNET]: "https://api.test.deriw.com/",
};

export function getServerBaseUrl(chainId) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId, path) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
